<template>
  <div class="ma-4">
    <admin-header>
      <template v-slot:right>
        <v-dialog v-model="addEventDialog" width="300">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" outlined>
              <v-icon left>fal fa-plus</v-icon>
              Add Event
            </v-btn>
          </template>
          <v-card>
            <v-card-title>Add Event</v-card-title>
            <v-card-text>
              <v-text-field v-model="add.name" label="Event Name" outlined></v-text-field>
              <date-picker v-model="add.date" label="Event Date" outlined></date-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="isSaving" text @click="addEventDialog = false">Cancel</v-btn>
              <v-btn :disabled="isSaving || add.name === '' || add.date === ''" text color="success" @click="doAddEvent">{{ isSaving ? 'Saving...' : 'Add Event' }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-header>
    <v-data-table :items="eventList" :headers="headers">
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date, true, true) }}</template>
    </v-data-table>
  </div>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/adminHeader'),
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  setup (props, { root }) {
    const eventList = ref([])
    const headers = ref([
      { text: 'Date', value: 'date' },
      { text: 'Event Name', value: 'name' },
      { text: 'Sheet', value: 'sheet' },
      { text: 'Students Scanned', value: 'students' }
    ])

    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const service = root.$feathers.service('student/music/scan-event')

    watch(term, async () => {
      const aggregate = [
        { $match: { term: term.value } },
        { $project: {
          _id: 1,
          date: 1,
          name: 1,
          sheet: 1,
          students: { $cond: { if: { $isArray: '$students' }, then: { $size: '$students' }, else: '0' } }
        } }
      ]
      const { data } = await service.find({ query: { aggregate } })
      eventList.value = data
    })

    const addEventDialog = ref(false)
    const add = ref({ date: '', name: '' })

    const isSaving = ref(false)
    async function doAddEvent () {
      isSaving.value = true
      const row = await service.create({ ...add.value, term: term.value })
      eventList.value.push(row)
      add.value = { date: '', name: '' }
      addEventDialog.value = false
      isSaving.value = false
    }

    return {
      eventList,
      headers,
      addEventDialog,
      add,
      isSaving,
      doAddEvent,
      stringFormatDate
    }
  }
}
</script>
